import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'

function LogoImage(props) {
  return (
    <StaticImage src="logo.jpeg" width="259" />
  )
}

export default LogoImage
