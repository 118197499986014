import React from "react"
import { Container, Row, Col } from "reactstrap"
import { FaFacebookSquare, FaInstagram } from "react-icons/fa"
import Link from "./link"
import styled from "styled-components"
import footerItems from "../data/footer.json"

const FooterStyling = styled.footer`
  padding: 3rem 0;
  background: #f8f9fa;
  a,
  a:hover {
    color: inherit;
  }
  ul {
    color: rgba(0, 0, 0, 0.5);
    -webkit-padding-start: 0;
    padding: 0;
    & > li {
      list-style: none;
      a,
      a:hover {
        color: inherit;
      }
    }
  }
`

let SocialLink = ({ Icon }) => (
  <Link to="/" className="mr-2">
    <Icon size={30} />
  </Link>
)

let FooterLink = ({ to, children }) => (
  <li>
    <Link to={to}>{children}</Link>
  </li>
)

let Footer = () => (
  <FooterStyling>
    <Container>
      <Row>
        <Col xs={12} md={4}>
          <div style="text-align: center;">
            <span style="color: rgb(102, 102, 102); font-size: 14px;">地址：</span>
            <span style=" font-size: 14px;"><font color="#666666">天津滨海高新区华苑产业区榕苑路15号9-D-401</font></span
            ><span style="font-size: 14px;"></span>
          </div>
        </Col>
        <Col xs={12} md={3}>
          <div style="text-align: center;">
            <span style="color: rgb(102, 102, 102); font-size: 14px;">联系电话：</span>
            <span style=" font-size: 14px;"><font color="#666666">022-83711756</font></span
            ><span style="font-size: 14px;"></span>
          </div>
        </Col>
        <Col xs={12} md={3}>
          <div style="text-align: center;">
            <span style="color: rgb(102, 102, 102); font-size: 14px;">邮箱：</span>
            <span style=" font-size: 14px;"><font color="#666666">1647382019@QQ.COM</font></span
            ><span style="font-size: 14px;"></span>
          </div>
        </Col>
        <Col xs={12} md={2}>
          <div style="text-align: center;">
            <span style="color: rgb(102, 102, 102); font-size: 14px;">陈经理：</span>
            <span style=" font-size: 14px;"><font color="#666666">13920868702</font></span
            ><span style="font-size: 14px;"></span>
          </div>
        </Col>
      </Row>
    </Container>
  </FooterStyling>
)

export default Footer
