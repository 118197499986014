import React from "react"
import { Container } from "reactstrap"
import Link from "../components/link"
let SubFooter = ({ title }) => (
  <div className="bg-light">
    <Container className="pb-3">
      <span className="text-secondary small">
        Copyright © {new Date().getFullYear()}. {title}. All rights reserved.
      </span>
      <a href="https://beian.miit.gov.cn" target="_blank" style="padding-left:20px;color: rgb(102, 102, 102); font-size: 12px;">津ICP备15002419号-1</a>
    </Container>
  </div>
)

export default SubFooter
